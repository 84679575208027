const questionsTranslations = {
    en: {
        purposeQuestion: 'What is the purpose of your property search?',
        purposeAnswer1: 'For living',
        purposeAnswer2: 'For investment',
        investExperienceQuestion: 'Have you invested in real estate abroad before?',
        investExperienceAnswer1: 'Yes',
        investExperienceAnswer2: 'No',
        propertyTypeQuestion: 'What type of property are you interested in?',
        propertyTypeAnswer1: 'Hotel rooms',
        propertyTypeAnswer2: 'Apartment',
        propertyTypeAnswer3: 'Penthouse',
    },
    ua: {
        purposeQuestion: 'Для яких цілей розглядаєте нерухомість?',
        purposeAnswer1: 'Для життя',
        purposeAnswer2: 'Для інвестиції',
        investExperienceQuestion: 'Інвестували в закордонну нерухомість раніше?',
        investExperienceAnswer1: 'Так',
        investExperienceAnswer2: 'Ні',
        propertyTypeQuestion: 'Який тип нерухомості вас цікавить?',
        propertyTypeAnswer1: 'Готельні номери',
        propertyTypeAnswer2: 'Квартира',
        propertyTypeAnswer3: 'Пентхаус',
    },
    ru: {
        purposeQuestion: 'Для каких целей рассматриваете недвижимость?',
        purposeAnswer1: 'Для жизни',
        purposeAnswer2: 'Для инвестиции',
        investExperienceQuestion: 'Инвестировали ли вы в зарубежную недвижимость ранее?',
        investExperienceAnswer1: 'Да',
        investExperienceAnswer2: 'Нет',
        propertyTypeQuestion: 'Какой тип недвижимости вас интересует?',
        propertyTypeAnswer1: 'Гостиничные номера',
        propertyTypeAnswer2: 'Квартира',
        propertyTypeAnswer3: 'Пентхаус',
    },
    tr: {
        purposeQuestion: 'GAYRİMENKULÜ NE AMAÇLA DEĞERLENDİRİYORSUNUZ?',
        purposeAnswer1: 'Yaşamak için',
        purposeAnswer2: 'Yatırım yapmak için',
        investExperienceQuestion: 'DAHA ÖNCE YURTDIŞI GAYRİMENKULE YATIRIM YAPTINIZ MI?',
        investExperienceAnswer1: 'Evet',
        investExperienceAnswer2: 'Hayır',
        propertyTypeQuestion: 'HANGİ GAYRİMENKUL TİPİ İLE İLGİLENİYORSUNUZ?',
        propertyTypeAnswer1: 'Otel daireleri',
        propertyTypeAnswer2: 'Daire',
        propertyTypeAnswer3: 'Penthouse',
    }
}

export default questionsTranslations;