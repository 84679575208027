import React from "react";
import "./PolicyPage.css";

const PolicyPageRu = () => {
    return (
        <main className="policy-page">
            <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПОЛЬЗОВАТЕЛЕЙ СЕРВИСА</h1>
            <p>Данная Политика конфиденциальности устанавливает порядок получения, хранения, обработки, использования и раскрытие персональных данных Пользователя. Персональные данные от Пользователей Веб-сайта при регистрации в форме обратной связи на Веб-сайте, а также при использовании Веб-сайта Пользователями получает ОДО Жилстрой-2, которому принадлежит сайт.</p>
            <section>
                <h2>1. Определение терминов</h2>
                <p>1.1 В настоящей Политике конфиденциальности используются следующие термины</p>
                <ul>
                    <li>Веб-сайт — веб-страница, расположенная в сети Интернет по адресу: https://quiz.desire-antalya.com/.
                    </li>
                    <li>Администрация Веб-сайта – уполномоченные сотрудники на управление Веб-сайтом, что действуют от имени ОДО "Жилстрой-2", которые организуют и осуществляют обработку
                     </li>
                    <li>Пользователь — лицо, разместившее персональные данные с помощью регистрации/пользования сервисами на сайте.
                    </li>
                    <li>Форма обратной связи – специальная форма, где Пользователь при регистрации размещает свои персональные данные для передачи их Администрации Веб-сайта.
                    </li>
                    <li>Персональные данные – сведения или совокупность сведений о физическом лице, которое идентифицирован или может быть конкретно идентифицирован.
                    </li>
                    <li>Обработка персональных данных – любое действие или совокупность действий, таких как сбор, регистрация, накопление, хранение, адаптирование, изменение, обновление, использование и распространение (распространение, реализация, передача), обезличивание, уничтожение персональных данных, в том числе с использованием информационных (автоматизированных) систем или без использование таких средств.
                    </li>
                    <li>Конфиденциальность персональных данных – обязательны для соблюдения Администрацией Веб-сайта требования относительно недопущение распространения персональных данных Пользователя без его согласия или при наличии другого законного основания.
                    </li>
                </ul>
            </section>
            <section>
                <h2>2. ОБЩИЕ
                    ПОЛОЖЕНИЯ</h2>
                <p>2.1. Данная Политика конфиденциальности является официальным типичным документом Администрации Веб-сайта и определяет порядок обработки персональных данных лиц, использующих Форму обратной связи этого Веб-сайта.</p>

                <p>2.2. Целью этой Политики конфиденциальности есть обеспечение должного защиты информации о Пользователе, в том числе его персональных данных от несанкционированного доступа и разглашения.
                </p>
                <p>2.3. Отношения, связанные со сбором, хранением, распространением и защитой персональных данных Пользователя регулируются этой Политикой конфиденциальности и действующим законодательством Украины.
                </p>
                <p>2.4. Действует редакция Политики конфиденциальности является публичным документом, разработана Администрацией Веб-сайта и доступна любому Пользователю сети Интернет при пользовании Веб-сайта.
                </p>
                <p>2.5. Администрация Веб-сайта имеет право вносить изменения в настоящую Политику конфиденциальности.
                </p>
                <p>2.6. При внесении изменений в Политики конфиденциальности, Администрация Веб-сайта сообщает об этом Пользователя путем размещение новой редакции Политики конфиденциальности на Веб-сайте.
                </p>
                <p>2.7. Использование Пользователем Веб-сайта означает согласие с этой Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                </p>
                <p>2.8. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Веб-сайта.
                </p>
                <p>2.9. Администрация Веб-сайта нет проверяет достоверность персональных данных, что предоставляются Пользователем Веб-сайта.</p>
            </section>
            <section>
                <h2>3. CONDITIONS AND PURPOSE OF COLLECTION AND PROCESSING OF USERS' PERSONAL DATA.</h2>
                <p>3.1. Personal data of the User such as: Full name of the User, contact phone number of the User,
                    email address (e-mail), as well as additional information provided by the User, are transferred by
                    the User to the Administration of the Website with the consent of the User.
                </p>
                <p>
                    3.2. Передача персональных данных Пользователем Администрации Веб-сайта через Форму обратной связи означает согласие Пользователя на передачу и обработку его персональных данных.
                </p>
                <p>
                    3.3. Администрация Веб-сайта осуществляет обработку информации о Пользователе, в т.ч. его персональных данных, таких как: ФИО Пользователя, контактный телефон Пользователя, адрес электронной почты (e-mail), а также дополнительной информации о Пользователе предоставляемой им по своему желанием.
                </p>
                <p>
                    3.4. Обработка персональных данных осуществляется на основе принципов: законности целей и способов обработки персональных данных; соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных; соответствия объема и характера обрабатываемых персональных данных, способам обработки персональных данных и целям обработки персональных данных; недопустимость объединения созданных для несовместимых между собой целей баз данных, что содержат персональные данные.
                </p>
                <p>
                    3.5. Администрация Веб-сайта осуществляет обработку персональных данных Пользователя с его согласия в целях: идентификации Пользователя; установки с Пользователем обратной связи, включая направление сообщений, запросов, касающихся использования Веб-сайта, предоставления услуг, обработки Заказов от Пользователя; сообщения Пользователю Веб-сайта о состоянии заказанной консультации или встречи; для  осуществления деятельности о предоставлении услуг; предоставления Пользователю информации рекламного характера; предоставления Пользователю информации о отримання услуг, которые предоставляются ОДО Жилстрой-2</p>
            </section>
            <section>
                <h2>4. ХРАНЕНИЕ И ИСПОЛЬЗОВАНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>4.1. Хранение персональных данных предполагает действия по обеспечению их целостности и соответствующего режима доступа к ним.
                </p><p>
                4.2. Персональные данные Пользователя сохраняются исключительно на электронных носителях и используются исключительно по назначению, оговоренным в п. 3 этой Политики конфиденциальности.</p>
            </section>
            <section>
                <h2>5. ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ТРЕТЬИМ ЛИЦАМ</h2>
                <p>5.1. Персональные данные Пользователя не передаются любым третьим лицам, за исключением случаев, прямо предусмотренных этой Политикой конфиденциальности.
                </p><p>
                5.2. Предоставление персональных данных Пользователя по запросом государственных органов и органов местного самоуправление осуществляется в порядке, предусмотренном законодательством Украины.
            </p><p>
                5.3. Распространение персональных данных без согласия субъекта персональных данных или уполномоченной им лица разрешается в случаях, определенных законом, и только (если это необходимо) в интересах национальной безопасности, экономического благополучия и прав человека.</p>
            </section>
            <section>
                <h2>6. СРОКИ ХРАНЕНИЯ И Уничтожения ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>6.1. Персональные данные Пользователя хранятся на электронном носители сайта бессрочно.
                </p><p>
                6.2. Персональные данные Пользователя уничтожаются при желании самого Пользователя на основании его обращения, или по инициативе Администратора Веб-сайта без объяснения причин путем удаления Администрацией Веб-сайта информации, размещенной Пользователем, а также в других случаях предусмотренных законодательством Украины.</p>
            </section>
            <section>
                <h2>7. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ</h2>
                <p>7.1. Пользователи имеют право:</p>
                <ul>
                    <li>знать о источниках сбора, местонахождении своих персональных данных, цели их обработки;
                    </li>
                    <li>на основании запроса получать от Администрации Веб-сайта информацию, что касается обработки его персональных данных.
                    </li>
                    <li>получать информацию о третьих лиц, которым передаются его персональные данные;
                    </li>
                    <li>предъявлять мотивированные требование владельцу персональных данных с возражением против обработки своих персональных данных;
                    </li>
                    <li>предъявлять мотивированные требования относительно изменения или уничтожение своих персональных данных, если эти данные обрабатываются незаконно ли недостоверными;
                    </li>
                    <li>отозвать согласие на обработку персональных данных;
                    </li>
                    <li>знать механизм автоматической обработки персональных данных.
                    </li>
                </ul>
                <p>7.2. Пользователи обязаны:</p>
                <ul>
                    <li>
                        предоставить информацию о персональных данных необходимых для использования Веб-сайта;
                    </li>
                    <li>соблюдать требования законодательства при пользовании Веб-сайтом и передачи персональных данных.
                    </li>
                </ul>
            </section>
            <section>
                <h2>8. МЕРОПРИЯТИЯ ДЛЯ ЗАЩИТЫ ИНФОРМАЦИИ О ПОЛЬЗОВАТЕЛЯХ</h2>
                <p>8.1. Администратор Веб-сайта предпринимает технические и организационно-правовые мероприятия с целью обеспечения защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, извращения, блокировки, копирования, распространения, от незаконной обработки, а также от других неправомерных действий.</p>
            </section>
            <section>
                <h2>9. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p>9.1. В случае нарушения норм законодательства о защите конфиденциальной информации, а также положений данной политики конфиденциальности стороны несут ответственность установленную законом.
                </p>
                <p>
                    9.2. В случае потери или разглашения Конфиденциальной информации Администрация Веб-сайта не несет ответственности, если данная конфиденциальная информация:
                    <ul>
                        <li>стала публичным достоянием до ее потери или разглашения;
                        </li>
                        <li>была получена от третьей стороны до момента ее получения Администрацией веб-сайта;
                        </li>
                        <li>была разглашена при согласии Пользователя.
                        </li>
                    </ul>
                </p>
            </section>
            <section>
                <h2>10. РЕШЕНИЕ СПОРОВ</h2>
                <p>10.1. В спорах, возникающих из отношений между ользователем Веб-сайта и Администрацией Веб-сайт, первоочередным является разрешение спора в досудебном порядке путем предъявления претензии.
                </p><p>
                10.2 Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно сообщает заявителю претензии о результатах рассмотрения претензии.
            </p><p>
                10.3. В случае не урегулирования спора в досудебном порядке стороны могут обратиться в суд соответственно требованиям действующего законодательства Украины.
            </p><p>
                10.4. К этой Политики конфиденциальности и отношений между Пользователем и Администрацией Веб-сайта применяется действующее законодательство Украины.</p>
            </section>
        </main>
    );
};

export default PolicyPageRu;