import React from "react";
import "./PolicyPage.css";

const PolicyPageUa = () => {
    return (
        <main className="policy-page">
            <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ КОРИСТУВАЧІВ СЕРВІСУ</h1>
            <p>Дана Політика конфіденційності встановлює порядок отримання, зберігання, обробки, використання і
                розкриття персональних даних Користувача. Персональні дані від Користувачів Веб-сайту при
                реєстрації в Формі зворотного зв’язку на Веб-сайті, а також під час використання Веб-сайту
                Користувачами отримує ТДВ Житлобуд-2, якому належить сайт.</p>
            <section>
                <h2>1. ВИЗНАЧЕННЯ ТЕРМІНІВ</h2>
                <p>1.1 У цій Політиці конфіденційності використовуються наступні терміни:</p>
                <ul>
                    <li>Веб-сайт — веб-сторінка, розташована в мережі Інтернет за адресою:
                        https://quiz.desire-antalya.com/.
                    </li>
                    <li>Адміністрація Веб-сайту – уповноважені співробітники на управління Веб-сайтом,
                        що
                        діють від імені ТДВ "Житлобуд-2", які організовують і здійснюють обробку
                        персональних
                        даних, а також визначають цілі обробки персональних даних, склад персональних
                        даних, що
                        підлягають обробці, дії або операції, що здійснюються з персональними даними.
                    </li>
                    <li>Користувач — особа, яка розмістила персональні дані за допомогою
                        реєстрації/користування сервісами на Веб-сайті.
                    </li>
                    <li>Форма зворотного зв’язку – спеціальна форма, де Користувач при реєстрації
                        розміщує
                        свої персональні дані з метою передачі їх Адміністрації Веб-сайту.
                    </li>
                    <li>Персональні дані – відомості чи сукупність відомостей про фізичну особу, яка
                        ідентифікована або може бути конкретно ідентифікована.
                    </li>
                    <li>Обробка персональних даних – будь-яка дія або сукупність дій, таких як
                        збирання,
                        реєстрація, накопичення, зберігання, адаптування, зміна, поновлення,
                        використання і
                        поширення (розповсюдження, реалізація, передача), знеособлення, знищення
                        персональних
                        даних, у тому числі з використанням інформаційних (автоматизованих) систем
                        або
                        без
                        використання таких засобів.
                    </li>
                    <li>Конфіденційність персональних даних
                        – обов’язкові для дотримання
                        Адміністрацією
                        Веб-сайту вимоги щодо
                        недопущення поширення
                        персональних даних Користувача
                        без
                        його
                        згоди або за наявності іншої
                        законної підстави.
                    </li>
                </ul>
            </section>
            <section>
                <h2>2. ЗАГАЛЬНІ
                    ПОЛОЖЕННЯ</h2>
                    <p>2.1. Дана
                        Політика
                        конфіденційності
                        є офіційним
                        типовим
                        документом
                        Адміністрації
                        Веб-сайту і
                        визначає порядок
                        обробки
                        персональних
                        даних осіб, що
                        використовують
                        Форму
                        зворотного
                        зв’язку на
                        Веб-сайті.</p>

                    <p>2.2. Метою цієї
                        Політики
                        конфіденційності
                        є забезпечення
                        належного
                        захисту
                        інформації
                        про Користувача,
                        в тому числі
                        його
                        персональних
                        даних від
                        несанкціонованого
                        доступу і
                        розголошення.
                    </p>
                    <p>2.3. Відносини,
                        пов’язані зі
                        збором,
                        зберіганням,
                        розповсюдженням
                        і захистом
                        персональних
                        даних
                        Користувача
                        регулюються цією
                        Політикою
                        конфіденційності
                        та
                        чинним
                        законодавством
                        України.
                    </p>
                    <p>2.4. Чинна
                        редакція
                        Політики
                        конфіденційності
                        є публічним
                        документом,
                        розроблена
                        Адміністрацією
                        Веб-сайту і
                        доступна
                        будь-якому
                        Користувачеві
                        мережі Інтернет
                        при
                        користуванні
                        Веб-сайтом.
                    </p>
                    <p>2.5.
                        Адміністрація
                        Веб-сайту має
                        право вносити
                        зміни в справжню
                        Політику
                        конфіденційності.
                    </p>
                    <p>2.6. При
                        внесенні змін до
                        Політики
                        конфіденційності,
                        Адміністрація
                        Веб-сайту
                        повідомляє
                        про це
                        Користувача
                        шляхом
                        розміщення нової
                        редакції
                        Політики
                        конфіденційності
                        на
                        Веб-сайті.
                    </p>
                    <p>2.7.
                        Використання
                        Користувачем
                        Веб-сайту
                        означає згоду з
                        цією Політикою
                        конфіденційності
                        та умовами
                        обробки
                        персональних
                        даних
                        Користувача.
                    </p>
                    <p>2.8. У разі
                        незгоди з
                        умовами Політики
                        конфіденційності
                        Користувач
                        повинен
                        припинити
                        використання
                        Веб-сайту.
                    </p>
                    <p>2.9.
                        Адміністрація
                        Веб-сайту не
                        перевіряє
                        достовірність
                        персональних
                        даних, що
                        надаються
                        Користувачем
                        Веб-сайту.</p>
            </section>
            <section>
                <h2>3. УМОВИ ТА ЦІЛІ
                    ЗБОРУ ТА ОБРОБКИ
                    ПЕРСОНАЛЬНИХ
                    ДАНИХ
                    КОРИСТУВАЧІВ.</h2>
                <p>3.1. Персональні
                    дані Користувача
                    такі як: ПІБ
                    Користувача,
                    контактний
                    телефон
                    Користувача,
                    адреса
                    електронної
                    пошти (e-mail),
                    а також
                    додаткова
                    інформація, що
                    надається
                    Користувачем,
                    передаються
                    Користувачем
                    Адміністрації
                    Веб-сайту за
                    згодою
                    Користувача.
                </p>
                <p>
                    3.2. Передача
                    персональних
                    даних
                    Користувачем
                    Адміністрації
                    Веб-сайту через
                    Форму
                    зворотного
                    зв’язку означає
                    згоду
                    Користувача на
                    передачу та
                    обробку його
                    персональних
                    даних.
                </p>
                <p>
                    3.3.
                    Адміністрація
                    Веб-сайту
                    здійснює обробку
                    інформації про
                    Користувача, в
                    т.ч.
                    його
                    персональних
                    даних, таких як:
                    ПІБ Користувача,
                    контактний
                    телефон
                    Користувача,
                    адреса
                    електронної
                    пошти (e-mail),
                    а також
                    додаткової
                    інформації про
                    Користувача, що
                    надається
                    ним за своїм
                    бажанням.
                </p>
                <p>
                    3.4. Обробка
                    персональних
                    даних
                    здійснюється на
                    основі
                    принципів:
                    законності
                    цілей і
                    способів обробки
                    персональних
                    даних;
                    відповідності
                    цілей обробки
                    персональних
                    даних
                    цілям,
                    заздалегідь
                    визначеним і
                    заявленим при
                    зборі
                    персональних
                    даних;
                    відповідності
                    обсягу та
                    характеру
                    оброблюваних
                    персональних
                    даних способам
                    обробки
                    персональних
                    даних
                    і цілям обробки
                    персональних
                    даних;
                    неприпустимість
                    об’єднання
                    створених для
                    несумісних
                    між собою цілей
                    баз даних, що
                    містять
                    персональні
                    дані.
                </p>
                <p>
                    3.5.
                    Адміністрація
                    Веб-сайту
                    здійснює обробку
                    персональних
                    даних
                    Користувача з
                    його
                    згоди в цілях:
                    ідентифікації
                    Користувача;
                    встановлення з
                    Користувачем
                    зворотного
                    зв’язку,
                    включаючи
                    напрямок
                    повідомлень,
                    запитів, що
                    стосуються
                    використання
                    Веб-сайту,
                    надання послуг,
                    обробки
                    Замовлень від
                    Користувача;
                    повідомлення
                    Користувача
                    Веб-сайту
                    про стан
                    замовленої
                    консультації або
                    зустрічі; для
                    здійснення
                    діяльності з
                    надання
                    послуг; надання
                    Користувачеві
                    інформації
                    рекламного
                    характеру;
                    надання
                    Користувачеві
                    інформації щодо
                    отримання
                    послуг, які
                    надаються ТДВ Житлобуд-2</p>
            </section>
            <section>
                <h2>4. ЗБЕРІГАННЯ І
                    ВИКОРИСТАННЯ
                    ПЕРСОНАЛЬНИХ
                    ДАНИХ</h2>
                <p>4.1. Зберігання
                    персональних
                    даних передбачає
                    дії щодо
                    забезпечення їх
                    цілісності та
                    відповідного
                    режиму доступу
                    до них.
                </p><p>
                4.2. Персональні
                дані Користувача
                зберігаються
                виключно на
                електронних
                носіях і
                використовуються
                виключно за
                призначенням,
                обумовленим в п.
                3 цієї Політики
                конфіденційності.</p>
            </section>
            <section>
                <h2>5. ПЕРЕДАЧА
                    ПЕРСОНАЛЬНИХ
                    ДАНИХ ТРЕТІМ
                    ОСОБАМ</h2>
                <p>5.1. Персональні
                    дані Користувача
                    не передаються
                    будь-яким третім
                    особам, за
                    винятком
                    випадків, прямо
                    передбачених
                    цією Політикою
                    конфіденційності.
                </p><p>
                5.2. Надання
                персональних
                даних
                Користувача за
                запитом
                державних
                органів та
                органів
                місцевого
                самоврядування
                здійснюється в
                порядку,
                передбаченому
                законодавством
                України.
            </p><p>
                5.3. Поширення
                персональних
                даних без згоди
                суб’єкта
                персональних
                даних або
                уповноваженої
                ним особи
                дозволяється у
                випадках,
                визначених
                законом, і лише
                (якщо це
                необхідно) в
                інтересах
                національної
                безпеки,
                економічного
                добробуту та
                прав
                людини.</p>
            </section>
            <section>
                <h2>6. ТЕРМІНИ
                    ЗБЕРІГАННЯ ТА
                    ЗНИЩЕННЯ
                    ПЕРСОНАЛЬНИХ
                    ДАНИХ</h2>
                <p>6.1. Персональні
                    дані Користувача
                    зберігаються на
                    електронному
                    носії сайту
                    безстроково.
                </p><p>
                6.2. Персональні
                дані Користувача
                знищуються при
                бажанні самого
                Користувача на
                підставі
                його звернення,
                або з ініціативи
                Адміністратора
                Веб-сайту без
                пояснення причин
                шляхом
                видалення
                Адміністрацією
                Веб-сайту
                інформації,
                розміщеної
                Користувачем, а
                також
                в інших
                випадках
                передбачених
                законодавством
                України.</p>
            </section>
            <section>
                <h2>7. ПРАВА ТА
                    ОБОВ’ЯЗКИ
                    КОРИСТУВАЧІВ</h2>
                <p>7.1. Користувачі
                    мають право:
                    <ul>
                        <li>знати про
                            джерела
                            збирання,
                            місцезнаходження
                            своїх
                            персональних
                            даних, мету їх
                            обробки;
                        </li>
                        <li>на підставі
                            запиту
                            отримувати від
                            Адміністрації
                            Веб-сайту
                            інформацію, що
                            стосується
                            обробки його
                            персональних
                            даних.
                        </li>
                        <li>отримувати
                            інформацію про
                            третіх осіб,
                            яким передаються
                            його персональні
                            дані;
                        </li>
                        <li>пред’являти
                            вмотивовану
                            вимогу
                            володільцю
                            персональних
                            даних із
                            запереченням
                            проти
                            обробки своїх
                            персональних
                            даних;
                        </li>
                        <li>пред’являти
                            вмотивовану
                            вимогу щодо
                            зміни або
                            знищення своїх
                            персональних
                            даних, якщо
                            ці дані
                            обробляються
                            незаконно чи є
                            недостовірними;
                        </li>
                        <li>відкликати
                            згоду на обробку
                            персональних
                            даних;
                        </li>
                        <li>знати механізм
                            автоматичної
                            обробки
                            персональних
                            даних.
                        </li>
                    </ul></p>
                <p>
                    <ul>
                        <li>
                            7.2. Користувачі
                            зобов’язані:
                            • надати
                            інформацію про
                            персональні дані
                            необхідні для
                            користування
                            Веб-сайтом;
                        </li>
                        <li>• дотримуватися
                            вимог
                            законодавства
                            при користуванні
                            Веб-сайтом та
                            наданні
                            персональних
                            даних.
                        </li>
                    </ul>
                </p>
            </section>
            <section>
                <h2>8. ЗАХОДИ ДЛЯ
                    ЗАХИСТУ
                    ІНФОРМАЦІЇ ПРО
                    КОРИСТУВАЧІВ</h2>
                <p>8.1.
                    Адміністратор
                    Веб-сайту
                    приймає технічні
                    та
                    організаційно-правові
                    заходи з
                    метою
                    забезпечення
                    захисту
                    персональних
                    даних
                    Користувача від
                    неправомірного
                    або
                    випадкового
                    доступу до них,
                    знищення,
                    перекручення,
                    блокування,
                    копіювання,
                    поширення, від
                    незаконної
                    обробки, а також
                    від інших
                    неправомірних
                    дій.</p>
            </section>
            <section>
                <h2>9.
                    ВІДПОВІДАЛЬНІСТЬ
                    СТОРІН</h2>
                <p>9.1. У разі
                    порушення норм
                    законодавства
                    про захист
                    конфіденційної
                    інформації, а
                    також
                    положень даної
                    політики
                    конфіденційності
                    сторони несуть
                    відповідальність
                    встановлену
                    законом.
                </p>
                <p>
                    9.2. У разі
                    втрати або
                    розголошення
                    Конфіденційної
                    інформації
                    Адміністрація
                    Веб-сайту не
                    несе
                    відповідальність,
                    якщо дана
                    конфіденційна
                    інформація:
                    <ul>
                        <li>стала
                            публічним
                            надбанням до її
                            втрати або
                            розголошення;
                        </li>
                        <li>була отримана
                            від третьої
                            сторони до
                            моменту її
                            отримання
                            Адміністрацією
                            Веб-сайту;
                        </li>
                        <li>була
                            розголошена за
                            згодою
                            Користувача.
                        </li>
                    </ul>
                </p>
            </section>
            <section>
                <h2>10. ВИРІШЕННЯ
                    СПОРІВ</h2>
                <p>10.1. У спорах,
                    що виникають із
                    відносин між
                    Користувачем
                    Веб-сайту та
                    Адміністрацією
                    Веб-сайту,
                    першочерговим є
                    вирішення спору
                    у досудовому
                    порядку шляхом
                    пред’явлення
                    претензії.
                </p><p>
                10.2 Отримувач
                претензії
                протягом 30
                календарних днів
                з дня отримання
                претензії,
                письмово
                повідомляє
                заявника
                претензії про
                результати
                розгляду
                претензії.
            </p><p>

                10.3. У випадку
                не врегулювання
                спору у
                досудовому
                порядку сторони
                можуть
                звернутися до
                суду відповідно
                до вимог чинного
                законодавства
                України.
            </p><p>

                10.4. До цієї
                Політики
                конфіденційності
                і відносин між
                Користувачем та
                Адміністрацією
                Веб-сайту
                застосовується
                чинне
                законодавство
                України.</p>
            </section>
        </main>
);
};

export default PolicyPageUa;